<script>
import _findIndex from "lodash/findIndex";

export default {
  values: [
    {
      labelBy: "Por Fecha",
      labelDirection: "Reciente a Antigua",
      value: {
        order: "DESC",
        orderby: "date",
      },
    },
    {
      labelBy: "Por Fecha",
      labelDirection: "Antigua a Reciente",
      value: {
        order: "ASC",
        orderby: "date",
      },
    },
    {
      labelBy: "Por Precio",
      labelDirection: "Mayor a Menor",
      value: {
        order: "DESC",
        orderby: "price",
      },
    },
    {
      labelBy: "Por Precio",
      labelDirection: "Menor a Mayor",
      value: {
        order: "ASC",
        orderby: "price",
      },
    },
  ],

  props: {
    active: Boolean,
    value: {
      default() {
        return {};
      },
      type: Object,
    },
  },

  computed: {
    current() {
      return _findIndex(this.$options.values, { value: this.value });
    },
  },

  methods: {
    submit(value = this.value) {
      this.$emit("input", value);
    },
  },
};
</script>

<template>
  <div class="finderMobileFilterView" :class="{ active }">
    <div class="app-bar filters__bar">
      <div class="btn--close">
        <b-button @click="submit()">
          <b-icon icon="times" />
        </b-button>
      </div>
      <h4 class="app-bar__title">Orden de anuncios</h4>
    </div>
    <div class="filters__body">
      <div class="widget">
        <div
          class="option"
          v-for="(option, index) in $options.values"
          :key="index"
          :class="{ current: index == current }"
          @click="submit(option.value)"
        >
          <div class="check"></div>
          <span class="by">{{ option.labelBy }}: </span>
          <span class="direction">{{ option.labelDirection }}</span>
        </div>
      </div>
    </div>
    <!-- <div class="filters__footer">
      <b-button rounded class="btn--finish" type="is-primary" @click="submit()">
        Ver resultados
      </b-button>
    </div> -->
  </div>
</template>

<style lang="scss">
// html[route="finder-filter-order"],
// html[route="finder-filter-order"] body {
//   background-color: #eaeaec;
// }
</style>

<style lang="scss" scoped>
.option {
  // background-color: #fff;
  // border: 1px solid $sitio2;
  border-radius: 0.75rem;
  margin-bottom: 1.5rem;
  padding: 1.25rem 1rem;
  display: flex;
  align-items: center;
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.06),
    -4px -4px 8px 0px rgb(255, 255, 255);
  .check {
    display: block;
    width: 24px;
    height: 24px;
    border-radius: 24px;
    // border: 2px solid $sitio2;
    margin-right: 0.75rem;
    background-color: rgba(0, 0, 0, 0.05);
    box-shadow: inset 1px 1px 2px 0px rgba(0, 0, 0, 0.08),
      inset -1px -1px 1px 0px #fff;
  }
  .by {
    color: $sitio2;
    font-weight: 500;
    margin-right: 0.375rem;
    // text-transform: uppercase;
  }
  .direction {
    font-weight: 500;
    color: #000;
  }
}
.option.current {
  background-color: rgba(0, 0, 0, 0.03);
  box-shadow: inset 2px 2px 3px 0px rgba(0, 0, 0, 0.05),
    inset -2px -2px 3px 0px rgb(255, 255, 255);
  .check {
    position: relative;
  }
  .check::after {
    position: absolute;
    display: block;
    content: "";
    width: 14px;
    height: 14px;
    // top: 0.5rem;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    border-radius: 14px;
    background-color: $sitio2;
    box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.15),
      -1px -1px 2px 0px rgb(255, 255, 255);
  }
  .direction {
    color: #6d7587;
  }
}
</style>
